<template>
    <div class="container">
        <qy-top-nav title="认证经销商"/>
        <van-form @submit="onSubmit">
            <van-field
                    v-model="form.dealerName"
                    label="经销商"
                    placeholder="请填写经销商名称"
                    required
                    :rules="[{required:true}]"
            />
            <van-field
                    v-model="form.name"
                    name="负责人"
                    label="负责人"
                    placeholder="请填写负责人姓名"
                    required
                    :rules="[{required:true}]"
            />
            <van-field
                    v-model="form.phone"
                    type="tel"
                    label="联系电话"
                    placeholder="请填写负责人电话"
                    required
                    maxlength="11"
                    :rules="[{required:true}]"
            />
            <van-field
                    v-model="form.licenseNo"
                    type="text"
                    label="营业执照编号"
                    placeholder="请填写营业执照编号"
                    required
                    :rules="[{required:true}]"
            />
            <van-field name="uploader" label="营业执照" required>
                <template #input>
                    <van-uploader v-model="licenseImgs" upload-text="添加图片"  :after-read="afterRead"  max-count="2" multiple/>
                </template>
            </van-field>

            <van-field name="uploader" label="店铺图片" required>
                <template #input>
                    <van-uploader v-model="images" upload-text="添加图片"  :after-read="afterRead"  max-count="3" multiple/>
                </template>
            </van-field>

            <van-field v-model="form.address" rows="2" autosize label="店铺地址" type="textarea" maxlength="50" placeholder="请输入店铺详细地址..." show-word-limit required
                       :rules="[{required:true }]"/>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
    import request from "@/utils/request";
    import { Toast } from 'vant';
    export default {
        name: "EditDealer",
        data(){
            return {
                images:[],
                licenseImgs:[],
                form:{
                    id:'',
                    dealerName:'',
                    name:'',
                    phone:'',
                    shopImg:'',
                    licenseImg:'',
                    licenseNo:'',
                    address:'',
                }
            }
        },
        created(){
            this.getList();
        },
        methods:{
            getList(){
                let id = this.getUrlParam("id");
                if(!id) return;
                let that = this;
                request.post('/api/dealer/queryDealerById/'+id).then(res => {
                    if (res.code == 0){
                        that.form = res.data;
                        if(that.form.shopImg){
                            let list = that.form.shopImg.split(',');
                            list.forEach(item => {
                               this.images.push({
                                 url: this.getImgUrl(item),
                                 file:{
                                     url:item,
                                 }
                               });
                            });
                            console.log(JSON.stringify(this.images))
                        }
                        if(that.form.licenseImg){
                            let list = that.form.licenseImg.split(',');
                            list.forEach(item => {
                                this.licenseImgs.push({
                                    url: this.getImgUrl(item),
                                    file:{
                                        url:item,
                                    }
                                });
                            });
                            console.log(JSON.stringify(this.licenseImgs))
                        }
                    }else {
                        //.........
                    }
                });
            },
            afterRead(file){
                console.log(JSON.stringify(file))
                let url = "/api/common/upload";
                let fd = new FormData;
                fd.append("file", file.file);
                this.$axios.post(url, fd).then((res) => {
                    let data = res.data;
                    if (data.code == 0) {
                        file.file.url = data.fileName;
                    } else {
                        Toast.fail("上传失败");
                    }
                }).catch(err => {
                    Toast.fail(err);
                });
            },
            onSubmit(){
                if(this.images.length <= 0){
                    return Toast.fail("店铺图片不能空!");
                }
                if(this.licenseImgs.length <= 0){
                    return Toast.fail("营业执照图片不能空!");
                }
                this.form.shopImg = this.convertArrayToStr(this.images);
                this.form.licenseImg = this.convertArrayToStr(this.licenseImgs);
                request.post('/api/dealer/edit',this.form).then(res => {
                    if(res.code == 0){
                        history.back();
                    }else {
                        return Toast.fail(res.msg);
                    }
                });
            },
            convertArrayToStr(imgArray){
                let tempArray = [];
                imgArray.forEach(file => {
                    tempArray.push(file.file.url);
                });
                return tempArray.join(',');
            }
        }
    }
</script>

<style scoped>

</style>